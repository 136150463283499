export const environment = {
  production: true,
  apiBaseUrl: 'https://b101t02as-backend01.azurewebsites.net',
  clientId: 'b53135e9-b9e5-43ec-98b7-ff5acac18be3',
  authority: 'https://login.microsoftonline.com/fad277c9-c60a-4da1-b5f3-b3b8b34a82f9',
  redirectUri: 'https://uat.funding-data.education.gov.uk/.auth/login/aad/callback',
  scope: ['https://fundingdataserviceapiuat/user_impersonation'],
  sysAdminGroup: 'AZURE-FDS-TE-SYSADMIN-USERS',
  useLocalHttpInterceptor: false,
  localToken: '',
  environment: 'uat',
  clarityProjectId: ''
};
